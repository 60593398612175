// import { mapGetters } from "vuex";
import axios from 'axios';
var statesList = require(`@/components/payment-link/states.json`);
import config from "@/config/app";
import {urlParser } from "@/utils/utility";
export default {
    data() {
        return {
        }
    },
      computed: {
        getStates() {
            return (state) => {
                return statesList[state] ? statesList[state] : [];
            }
        },
      },
    methods: {
        async getLocation() {
            try{
            this.loading = true;
            this.loadingText = "Finding your location...";
            let data = await axios.get('https://ipapi.co/json/');
            this.loading = false;
            this.loadingText = "";
            this.location= data.data;
            this.form.zipCode=data.data.postal;
            
            this.form.country = data.data.country_code;
            this.states = this.getStates(data.data.country_code);
            this.form.state = data.data.region_code;
            }
            catch(err)
            {
                console.log(err)
            }
        },

        
        async createCustomer(customerData) {
            try {
                this.loading = true;
                this.loadingText = "Verifying the user details....... Please wait.....";
                this.customerInfo={status:false};
                const customer = await axios.post(`${config.PAYMENT_GATEWAY_URL}/createCustomer?pgAccountId=${this.pgAcId}&txnAcId=${this.txnAcId.sessionData._id}`, customerData);
                this.customerInfo= { ...customer.data, status: true };
            }
            catch (err) {
                this.customerInfo= { status: false, message: err.response.data.data.error.response.response_desc };
            }
        },
        async createCCPayMethod(payMethodData) {
            try {
                this.loading = true;
                this.loadingText = "Validating the card details....... Please wait.....";
                this.payMethodInfo= {status:false}
                const payMethod = await axios.post(`${config.PAYMENT_GATEWAY_URL}/createCCPaymethodToCustomer?pgAccountId=${this.pgAcId}&txnAcId=${this.txnAcId.sessionData._id}`, payMethodData);
                this.payMethodInfo= { ...payMethod.data, status: true,type:'CARD' };
            }
            catch (err) {
                console.log("createCCPayMethod",err)
                this.payMethodInfo=  { status: false, message: err.response.data.data.error.response.response_desc,type:'CARD' };
            }
        },

        async createAchMethod(achPayMethodData)
        {
            try {
                this.loading = true;
                this.loadingText = "Validating the Bank details....... Please wait.....";
                this.payMethodInfo= {status:false}
                const payMethod = await axios.post(`${config.PAYMENT_GATEWAY_URL}/createECheckPayMethodToCustomer?pgAccountId=${this.pgAcId}&txnAcId=${this.txnAcId.sessionData._id}`, achPayMethodData);
                this.payMethodInfo= { ...payMethod.data, status: true,type:'ACH' };
            }
            catch (err) {
                console.log("createACHPayMethod",err)
                this.payMethodInfo=  { status: false, message: err.response.data.data.error.response.response_desc,type:'ACH' };
            }
        },        
        async paymentInitiate(data) {
            try {
                this.loading = true;
                this.loadingText = "Initiating the transaction....... Please wait.....";
                this.paymentInfo={ status: true }
                const paymentCheckout = await axios.post(`${config.PAYMENT_GATEWAY_URL}/initiatePayment?pgAccountId=${this.pgAcId}&txnAcId=${this.txnAcId.sessionData._id}`, data);
                this.paymentInfo= { ...paymentCheckout.data, status: true };
            }
            catch (err) {

                console.log("paymentInitiate",err)
                this.paymentInfo= { status: false, message: err.response.data.data.error.response.response_desc };
            }
        },

        async verifyTransaction(txnId) {
            try {
                this.loading = true;
                this.loadingText = "Verifying the transaction....... Please wait.....";
                this.transactionInfo= { status: true }
                let payload = {
                    env: this.activePaymentSystem && this.activePaymentSystem.isLive?1:0,
                    orgId: "",
                    locId: "",
                    trnId: txnId
                }
                const transactionInfo = await axios.post(`${config.PAYMENT_GATEWAY_URL}/getTransactionById?pgAccountId=${this.pgAcId}&txnAcId=${this.txnAcId.sessionData._id}`, payload);
                this.transactionInfo= { ...transactionInfo.data, status: true }
            }
            catch (err) {
                console.log("verifyTransaction",err)
                this.transactionInfo=  { status: false, message: err.response.data.data.error.response.response_desc };
            }

        },

        async validateSession() {
            try {
                let setupSessionData = {
                    env: this.activePaymentSystem && this.activePaymentSystem.isLive?1:0,
                    orgId: '',
                    locId: '',
                    partnerAccountId: '',
                    currencyCode: this.transactionInfo.currency,
                    cardType: 'CARD',
                    paymentToken: this.checkoutInfo.paymethod_token
                }

                let setupSessionInfo = await this.setupSession(setupSessionData);
                if (!setupSessionInfo.status) {
                    this.loading = false;
                    this.loadingText = "Please wait.... ";
                    this.errorPopup(setupSessionInfo.message, "Error");
                }

                if (setupSessionInfo.result.status == "VERIFIED") {
                    this.checkoutInfo.authorization_amount = this.userEnteredAmount * 100;
                    this.checkoutInfo.action = 'CARD';
                    this.proceedToPayment(this.checkoutInfo);
                }
                else {
                    // Still not yet verified.

                    this.payMethodValidationLink = setupSessionInfo.result.nextActionUrl;
                    this.loading = false;
                    this.$bvModal.msgBoxOk('Still Not  Verified! Please verify  your CC', {
                        title: 'Info',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        headerClass: 'p-2 border-bottom-0',
                        footerClass: 'p-2 border-top-0',
                        centered: true
                    })
                        .then(value => {
                            // this.boxTwo = value
                            console.log(value)
                        })
                        .catch(err => {
                            console.log("validateSession",err)
                            // An error occurred
                        })
                }
            }
            catch (err) {
                console.log("validateSession",err)
                this.loading = false;
                this.loadingText = "Please wait.... ";
                this.errorPopup(err.message, "Error");
            }
        },

        async setupSession(data) {
            try {
                this.loading = true;
                this.loadingText = "Establishing the connection....... Please wait.....";
                this.setupSessionInfo= { status: true }
                const setupSession = await axios.post(`${config.PAYMENT_GATEWAY_URL}/setupSession?pgAccountId=${this.pgAcId}&txnAcId=${this.txnAcId.sessionData._id}`, data);
                this.setupSessionInfo= { ...setupSession.data, status: true }
            }
            catch (err) {
                console.log("setupSession",err)
                this.setupSessionInfo= { status: false, message: err.response.data.data.error.response.response_desc };
            }


        },

        async addTransactionToVerify(result, pgType) {
            let paymentData = {
                amount: this.amount,
                currency:this.currency,
                entityId: this.transactionAccount.entityId,
                templateId: this.transactionAccount.templateId,
                entityDataId: this.transactionAccount.entityDataId,
                payModes: [],
                updateDataKey: this.transactionAccount.sessionData.updateDataKey,
                reference_key: this.transactionAccount.sessionData.updateDataKey,
                key: this.transactionAccount.sessionData.key,
                updateApiLink: "",
                apiUrl: '',
              };

              var pB64 = this.$CryptoJS.AES.encrypt(
                JSON.stringify(paymentData),
                "paymentLink"
              ).toString();
              var pE64 = this.$CryptoJS.enc.Base64.parse(pB64);
              var pHex = pE64.toString(this.$CryptoJS.enc.Hex);
            let transactionDetails = {
                pgType: pgType,
                source: config.PAYMENT_GATEWAY_SOURCE_KEY,
                pgId: this.pgAcId,
                txnACId: this.txnAcId.sessionData._id,
                txnId: result.transaction_id,
                isCompleted: false,
                status: "READY",
                current_status: "READY",
                partnerAccountId: null,
                otherInfo: {
                    usertimezone: new Date().getTimezoneOffset(),
                    paymethod: this.checkoutInfo.paymethod_token,
                    customerId: this.customer.customerId,
                    recurringConfirmation:this.recurringConfirmation?this.recurringConfirmation:0,
                    // email: this.email,
                },
                encryptedData: pHex
            };
            const apiResponse = await axios.post(`${config.PAYMENT_GATEWAY_URL}/add_transaction`, transactionDetails, {
                headers: {
                    usertimezone: new Date().getTimezoneOffset()
                }
            });
            console.log(apiResponse)
            if(apiResponse.data)
            {
            this.paymentInfoPopUp('Payment Initiated !',`We're excited to inform you that your payment of ${this.currency} ${this.amount}  has been successfully Initiated! 🚀`,apiResponse.data.reference_id,apiResponse.data.txnId, this.amount,this.currency,this.transactionAccount?.location);
            }
        },

        async paymentStatusUpdate(result, paymethod, customerId) {
            try {
                this.loading = true;
                this.loadingText = "Updating the payment status....... Please wait.....";

                let paymentObj = {
                    txn_id: result.other.receiptNumber,
                    gateway: "SWIREPAY",
                    paymentId: result.other.gid,
                    name: result.other.customer.name,
                    email: result.other.customer.email,
                    phoneNumber: result.other.customer.phoneNumber ? result.other.customer.phoneNumber : "+19999999999",
                    paymentSession: result.other.psClientSecret,
                    amount: result.other.amount,
                    amountRefunded: result.other.amountRefunded,
                    description: "Payment Trough Link",
                    paymentDate: result.other.createdAt,
                    refundDate: result.other.refundDate,
                    status: result.other.status,
                    paymentType: result.other.paymentMethod.paymentType.category,
                    method: "",
                    currency: result.other.currency.name,
                    payMethod: paymethod,
                    customerId: customerId,
                    pgAccId: this.pgAcId,
                    txnAcId: this.txnAcId.sessionData._id,
                    authCode: result.other.authCode,
                    authorizationId: result.other.authorizationId,
                    //   autoPay: formData.automaticPay
                }
                let finalObj = {
                    customer: {
                        id: result.other.gid,
                        amount: (result.other.amount) / 100,
                        authCode: result.other.authCode,
                        authorizationId: result.other.authorizationId,
                        psClientSecret: result.other.psClientSecret,
                        customer: result.other.customer.gid,
                        customerEmail: result.other.customer.email,
                        customerName: result.other.customer.name,
                        payMethod: result.other.paymentMethod.gid,
                        pgAccId: this.pgAcId,
                        txnAcId: this.txnAcId.sessionData._id,
                        recurringConfirmation:this.recurringConfirmation?this.recurringConfirmation:0,

                    },
                    txnDetails: paymentObj,
                    amount: this.amount,
                    formBuilderId: "",
                    currency: this.currency,
                    entityId: this.transactionAccount.entityId,
                    location: "",
                    type: "",
                    templateId: this.transactionAccount.templateId,
                    paymentInit: this.amount,
                    entityDataId: this.transactionAccount.entityDataId,
                    key: this.transactionAccount.sessionData.key,
                    updateDataKey: this.transactionAccount.sessionData.updateDataKey,
                    formBuilderDataId: "",
                    status: "SUCCESS",
                }
                const apiResponse = await axios.post(`${config.API_URL}/updateRulesOfPayBtn`, finalObj, {
                    headers: {
                        usertimezone: new Date().getTimezoneOffset()
                    }
                });
                if (apiResponse.data) {
                        this.paymentInfoPopUp('Payment Successful !',`We're excited to inform you that your payment of ${this.currency} ${this.amount}  has been successfully processed! 🚀`,paymentObj.paymentId,paymentObj.txn_id, (result.other.amount) / 100,this.currency,this.transactionAccount?.location);
                }
            }
            catch (err) {
                console.log("paymentStatusUpdate",err)
            }
        },

        async paymentInfoPopUp(title,msg,reference_id,transactionId,amount,currency,location=null)
        {
            const h = this.$createElement;
                this.$msgbox({
                  title: title,
                  message: h('p', null, [
                    h('span', null, msg),
                    h('br',null,''),
                    h('span',null,`Reference Id is `),
                    h('b', { style: 'color: teal' }, `${reference_id} `),
                    h('span',null,`and the transaction id is `),
                    h('b', { style: 'color: teal' }, ` ${transactionId}`),
                  ]),
                  showCancelButton: false,
                  confirmButtonText: 'OK',
                  showClose:false,
                  closeOnClickModal:false,
                  closeOnPressEscape:false,
                  closeOnHashChange:false,

                //   cancelButtonText: 'Cancel',
                //   beforeClose: (action, instance, done) => {
                //     if (action === 'confirm') {
                //       instance.confirmButtonLoading = true;
                //       instance.confirmButtonText = 'Loading...';
                //       setTimeout(() => {
                //         done();
                //         setTimeout(() => {
                //           instance.confirmButtonLoading = false;
                //         }, 300);
                //       }, 3000);
                //     } else {
                //       done();
                //     }
                //   }
            }).then(async (action) => {
                console.log("Confirm ",action,this.txnAcId.description)
                if(this.category==='FORM_BUILDER')
                {
                    // Refresh the form builder data
                    this.$bus.$emit("closePopUPsAfterPayMentFromFormBuilder");
                    this.$bus.$emit("refreshFormbuilderAfterPayment",this.txnAcId.description);
                    // Swal.close();
                }
                                    else if(location)
                {
                    console.log("Location block",urlParser(location))
                    window.location.href=urlParser(location);
                }
                else{
                    console.log("Else Location block",location)
                    this.$router.push({ name: this.$route.name, params: this.$route.params, query: {...this.$route.query,timestamp: Date.now(), ...{refresh_data: true}} })
                }
                });
        }
    },
};
